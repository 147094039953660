import * as React from "react"
import SEO from "../../components/seo";
import LayoutRechtsgebiete from "../../components/LayoutRechtsgebiete";


export default () => {

    return (
        <LayoutRechtsgebiete>
            <div id="content" className="animate-fade-in">
                <h1>Strafrecht</h1>
                <p>
                    Wenn es um die Frage schuldig oder nicht schuldig geht und damit Ihre persönliche oder materielle
                    Freiheit auf dem Spiel steht, sollte man nichts dem Zufall überlassen und mich immer kontaktieren.
                    Ich
                    vertrete und verteidige Sie im gesamten Bereich des allgemeinen Strafrechts, unter anderem
                </p>
                <ul>
                    <li>in allgemeinen Strafsachen, im Strafprozess</li>
                    <li>bei Durchsuchungen, Berufungsverfahren</li>
                    <li>in Ermittlungsverfahren, Strafanzeigen, Strafbefehle</li>
                    <li>Haftbeschwerden, Haftprüfungen und Bewährungen</li>
                    <li>in Nebenklagen als Zeugenbeistand</li>
                    <li>Jugendstrafrecht</li>
                </ul>
                <p>
                    Gemeinsam werde ich mit Ihnen eine Verteidigungsstrategie zur Erreichung der bestmöglichen
                    Ergebnisse
                    festlegen und bei Gericht und Staatsanwaltschaft durchsetzen.
                </p>
            </div>
        </LayoutRechtsgebiete>
    )
};

export const Head = () => {
    return (<SEO title={'Strafrecht'}/>);
}
